<template>
  <v-card class="mx-auto mt-10" flat>
    <v-card-title
      >Expense Items
      <v-spacer />
      <v-btn
        color="primary"
        class="ml-2 white--text"
        @click="addNew"
        v-if="displayImprestLineActions"
      >
        <v-icon dark>mdi-plus</v-icon>Add Expense Item
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="expenseItemsList"
      :search="search"
      class="elevation-0"
      fixed-header
    >
      <v-divider inset></v-divider>
      <!--eslint-disable-next-line -->
      <template v-slot:item.code="{ item }">
        <v-autocomplete
          @change="selectExpenseCode"
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.expenseCode"
          :items="expenseItems"
          :item-text="(item) => `${item.code} - ${item.description}`"
          :item-value="(item) => item.code"
          :hide-details="false"
          label="Select Expense Item"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-autocomplete>
        <span v-else>{{ item.expenseCode }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.description="{ item }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.description"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.description }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.Quantity="{ item, index }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          type="number"
          @input="assignQuantity($event, index)"
          onkeyup="if(this.value<0){this.value= this.value * -1}"
          min="1"
          v-model.number="expenseItem.Quantity"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.Quantity }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.unitOfMeasure="{ item }">
        <v-select
          @focusout="autosaveExpenseItem"
          v-model="expenseItem.unitOfMeasure"
          :items="unitsOfMeasure"
          :item-text="(item) => item.displayName"
          :item-value="(item) => item.code"
          :hide-details="false"
          label="Unit Of Measure"
          dense
          single-line
          v-if="displayConditionally(item)"
          class="mb-n3"
        ></v-select>
        <span v-else>{{ item.unitOfMeasure }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.unitCost="{ item, index }">
        <v-text-field
          @focusout="autosaveExpenseItem"
          @input="assignUnitCost($event, index)"
          v-model.number="expenseItem.unitCost"
          :hide-details="true"
          dense
          single-line
          v-if="displayConditionally(item)"
        ></v-text-field>
        <span v-else>{{ item.unitCost | currencyFormat }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.amount="{ item }">
        <span>{{
          Number(item.Quantity * item.unitCost) | currencyFormat
        }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.budgetMTD="{ item }">
        <span>{{ item.budgetMTD | currencyFormat }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.budgetYTD="{ item }">
        <span>{{ item.budgetYTD | currencyFormat }}</span>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.dimensions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color="primary"
              small
              class="ml-2 white--text text-caption font-weight-thin"
              @click="addDimensions(item)"
              v-bind="attrs"
              v-on="on"
            >
              Dimensions
            </v-chip>
          </template>
          <span>click to edit</span>
        </v-tooltip>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }" v-if="displayImprestLineActions">
        <div>
          <div v-if="displayConditionally(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  class="mr-3"
                  @click="close"
                >
                  mdi-window-close
                </v-icon>
              </template>
              <span>Close Editing</span>
            </v-tooltip>
            <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
          <div v-else>
            <v-icon color="green" class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
          </div>
        </div>
      </template>
      <!--eslint-disable-next-line -->
      <template v-slot:body.append>
        <tr>
          <th class="text-center"><h3>TOTAL AMOUNT</h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th><h3></h3></th>
          <th class="text-center">
            <h3>{{ imprestTotal | currencyFormat }}</h3>
          </th>
        </tr>
      </template>
    </v-data-table>
    <DimensionsDialog
      :itemDialog="itemDialog"
      :selectedExpenseItem="expenseItem"
      :formData="formData"
      v-on:close-dialog="closeDialog"
      :isEdit="isEdit"
      :locations="locations"
      :requisition="requisition"
      :dimensionValues="dimensionValues"
      :displayImprestLineActions="displayImprestLineActions"
      @updateDimensions="updateDimensions"
    />
  </v-card>
</template>
<script>
import DimensionsDialog from "./ImprestDimensionsDialog.vue";
export default {
  name: "expenseItems",
  components: {
    DimensionsDialog,
  },
  props: {
    selectedExpenseItem: Object,
    formData: Object,
    isEdit: Boolean,
    locations: Array,
    requisition: Object,
    dimensionValues: {
      type: Array,
      default: () => [],
    },
    currencyCode: {
      type: String,
      default: "KES",
    },
    routeParams: String,
    displayImprestLineActions: Boolean,
    settings: Object,
    autoFill: String,
  },
  data() {
    return {
      search: "",
      amount: 0,
      itemDialog: false,
      expenseItemIndex: -1,
      expenseItem: {
        id: 0,
        documentType: "Imprest",
        expenseCode: "",
        description: "",
        documentNo: this.routeParams,
        Quantity: 1,
        unitCost: 1,
        unitOfMeasure: "",
        base64Attachment: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
      },
      defaultExpenseItem: {
        id: 0,
        documentType: "Imprest",
        expenseCode: "",
        description: "",
        documentNo: this.routeParams,
        Quantity: 1,
        unitCost: 1,
        unitOfMeasure: "",
        base64Attachment: "",
        shortcutDimension1Code: "",
        shortcutDimension2Code: "",
        shortcutDimension3Code: "",
        shortcutDimension4Code: "",
        globalDimension5Code: "",
        globalDimension6Code: "",
        globalDimension7Code: "",
        globalDimension8Code: "",
        dimensionSpeedkeyCode: "",
        fundNo: "",
      },
    };
  },
  computed: {
    /**
     * Total amount of the imprest
     */
    imprestTotal() {
      return this.formData.imprestLinesAPI.reduce((acc, a) => {
        return parseFloat(acc) + parseFloat(Number(a.Quantity * a.unitCost));
      }, 0);
    },
    expenseItems() {
      return this.$store.getters["imprest/expenseCodes"];
    },
    unitsOfMeasure() {
      return this.$store.getters["imprest/imprestGetters"]("unitsOfMeasure");
    },
    expenseItemsList: {
      get() {
        this.formData.imprestLinesAPI.forEach((object) => {
          delete object.detailedImprestLines;
          delete object.amountToPay;
          delete object.balance;
        });
        return this.formData.imprestLinesAPI;
      },
      set(val) {
        return val;
      },
    },
    headers: function () {
      return [
        {
          text: "Code",
          value: "code",
          sortable: false,
          align: "center",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          align: "center",
        },
        {
          text: "Quantity",
          value: "Quantity",
          sortable: false,
          align: "center",
        },
        {
          text: "Unit of Measure",
          value: "unitOfMeasure",
          sortable: false,
          align: "center",
        },
        {
          text: `Unit Cost (${this.currencyCode})`,
          sortable: true,
          value: "unitCost",
          align: "center",
        },
        {
          text: `Amount (${this.currencyCode})`,
          sortable: true,
          value: "amount",
          align: "center",
        },
        {
          text:
            this.displayImprestLineActions && this.displayBudget === true
              ? `budgetMTD (${this.currencyCode})`
              : "",
          sortable: true,
          value:
            this.displayImprestLineActions && this.displayBudget === true
              ? "budgetMTD"
              : "",
          align: "center",
        },
        {
          text:
            this.displayImprestLineActions && this.displayBudget === true
              ? `budgetYTD (${this.currencyCode})`
              : "",
          sortable: true,
          value:
            this.displayImprestLineActions && this.displayBudget === true
              ? "budgetYTD"
              : "",
          align: "center",
        },
        {
          text: "Dimensions",
          value: "dimensions",
          sortable: false,
          align: "center",
        },
        {
          text: this.displayImprestLineActions ? "Actions" : "",
          sortable: true,
          value: this.displayImprestLineActions ? "actions" : "",
        },
      ];
    },
    setDialog: {
      get() {
        return this.itemDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    displayBudget() {
      return this.settings ? this.settings.show_budget_balances : null;
    },
  },
  methods: {
    autosaveExpenseItem() {
      if (this.expenseItemIndex > -1) {
        this.expenseItem.code = this.expenseItem.expenseCode;
        if (this.isEdit) {
          this.expenseItem.documentNo = this.formData.no;
          this.expenseItem.documentType = this.formData.documentType;
        } else {
          delete this.expenseItem.documentNo;
          delete this.expenseItem.documentType;
        }
        Object.assign(
          this.expenseItemsList[this.expenseItemIndex],
          this.expenseItem
        );
      }
    },
    displayConditionally(item) {
      if (item !== null) {
        if ("lineNo" in item) {
          if (item.lineNo === this.expenseItem.lineNo) {
            return true;
          }
          return false;
        } else if ("id" in item) {
          if (item.id === this.expenseItem.id) {
            return true;
          }
        }
      }
      return false;
    },
    editItem(item) {
      delete item.amount;
      delete item.documentDate;
      delete this.documentType;
      this.expenseItemIndex = this.expenseItemsList.indexOf(item);
      this.expenseItem = Object.assign({}, { ...item });
    },
    deleteItem: function (item) {
      if ("lineNo" in item) {
        this.$confirm.show({
          text: "Are you sure you want to remove this Item?",
          onConfirm: () => {
            this.$store.dispatch("imprest/deleteExpenseItem", item);
            for (const i in this.formData.imprestLinesAPI) {
              if (this.formData.imprestLinesAPI[i].lineNo === item.lineNo) {
                this.formData.imprestLinesAPI.splice(i, 1);
                break;
              }
            }
          },
        });
      } else {
        for (const i in this.formData.imprestLinesAPI) {
          if (
            this.formData.imprestLinesAPI[i].expenseCode === item.expenseCode
          ) {
            this.formData.imprestLinesAPI.splice(i, 1);
            break;
          }
        }
      }
    },
    closeDialog: function (val) {
      this.itemDialog = val;
    },
    close() {
      setTimeout(() => {
        this.expenseItem = Object.assign({}, this.defaultExpenseItem);
        this.expenseItemIndex = -1;
      }, 300);
    },
    addNew() {
      const addObj = Object.assign({}, this.defaultExpenseItem);
      addObj.id = this.expenseItemsList.length + 1;
      this.expenseItemsList.unshift(addObj);
      this.editItem(addObj);
      this.expenseItem.shortcutDimension1Code =
        this.formData.shortcutDimension1Code;
      this.expenseItem.shortcutDimension2Code =
        this.formData.shortcutDimension2Code;
      this.expenseItem.shortcutDimension3Code =
        this.formData.shortcutDimension3Code;
      this.expenseItem.shortcutDimension4Code =
        this.formData.shortcutDimension4Code;
      this.expenseItem.globalDimension5Code =
        this.formData.globalDimension5Code;
      this.expenseItem.globalDimension6Code =
        this.formData.globalDimension6Code;
      this.expenseItem.globalDimension7Code =
        this.formData.globalDimension7Code;
      this.expenseItem.globalDimension8Code =
        this.formData.globalDimension8Code;
      this.expenseItem.fundNo = this.formData.fundNo;
      this.expenseItem.dimensionSpeedkeyCode =
        this.formData.dimensionSpeedkeyCode;
    },
    selectExpenseCode: function () {
      var values = this.expenseItems.map(function (o) {
        return o.code;
      });
      var index = values.indexOf(this.expenseItem.expenseCode);
      this.expenseItem.description = this.expenseItems[index].description;
      this.expenseItem.unitOfMeasure = this.expenseItems[index].unitOfMeasure;
    },
    addDimensions(item) {
      delete item.amount;
      delete item.documentDate;
      delete this.documentType;
      this.expenseItemIndex = this.expenseItemsList.indexOf(item);
      this.expenseItem = Object.assign({}, { ...item });
      this.itemDialog = true;
    },
    assignUnitCost(val, index) {
      this.expenseItemsList[index].unitCost = val;
    },
    assignQuantity(val, index) {
      this.expenseItemsList[index].Quantity = val;
    },
    updateDimensions(val) {
      if ("lineNo" in val) {
        Object.assign(
          this.expenseItemsList[
            this.expenseItemsList.findIndex((el) => el.lineNo === val.lineNo)
          ],
          this.expenseItem
        );
      } else if ("id" in val) {
        Object.assign(
          this.expenseItemsList[
            this.expenseItemsList.findIndex((el) => el.id === val.id)
          ],
          this.expenseItem
        );
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  /* background: #555; */
  color: #fff;
}
.w-100 {
  width: 100%;
}
</style>
