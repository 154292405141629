<template>
  <v-container class="pb-10">
    <div class="d-flex flex-column mt-7">
      <Logo class="align-self-center py-10" :width="'10%'" :height="'10%'" />
    </div>
    <v-card elevation="10" class="mx-auto py-3 loginCard">
      <div v-if="checkingIfAuthenticated" class="text-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <v-card-actions class="d-flex flex-column">
          <v-btn href="/auth/login" color="primary" block>Go Back</v-btn>
        </v-card-actions>
      </template>
    </v-card>
    <auth-footer />
  </v-container>
</template>

<script>
import AuthFooter from "./partials/AuthFooter.vue";
import Logo from "@/packages/cassandra-base/app/components/Logo.vue";

export default {
  name: "SocialAuthCallback",
  components: { Logo, AuthFooter },

  mounted() {
    if (this.$route.query.code) {
      this.$store.dispatch("auth/socialLoginCallback", {
        code: this.$route.query.code,
        provider: "microsoft",
      });
    }
  },

  computed: {
    checkingIfAuthenticated: function () {
      return this.$store.getters["auth/checkingIfAuthenticatedStatus"];
    },
  },
};
</script>

<style>
.loginCard {
  width: 40%;
}
@media only screen and (max-width: 600px) {
  .loginCard {
    width: 100%;
  }
}
</style>
