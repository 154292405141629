<template>
  <div>
    <v-container>
      <AppBar :isApprover="isApproval" :path="path" />
      <v-card elevation="0">
        <v-card class="d-flex" flat>
          <v-card class="mr-auto my-2 mx-2" flat>
            <div class="d-flex justify-start">
              <span class="text-lg-h6"> Application Code: </span>
              <div class="mx-2 pt-1">
                <v-chip color="primary" label>
                  {{
                    application !== undefined
                      ? application.application_code
                      : ""
                  }}
                </v-chip>
              </div>
              <div class="mx-2">
                <TopBar :leaveEmployee="employee" />
              </div>
            </div>
          </v-card>
          <v-card class="pa-2 d-flex justify-end" flat v-if="Sendaprovalbutton">
            <div class="mx-2">
              <v-btn class="btnActions" @click="goBack()">
                <v-icon class="mx-3">arrow_back</v-icon>
                go back
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn
                color="primary"
                class="btnActions"
                v-if="application.status === 'Open'"
                @click.prevent="sendApproval(application)"
              >
                <v-icon class="mx-3">cloud_upload</v-icon>
                Send For Approval
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn
                color="primary"
                class="btnActions"
                @click.prevent="editApplication(application.application_code)"
              >
                <v-icon class="mx-3">mode_edit</v-icon>
                Edit Application
              </v-btn>
            </div>
          </v-card>
          <v-card
            class="pa-2 d-flex justify-end"
            flat
            v-if="approveRejectbutton"
          >
            <div class="mx-2">
              <v-btn
                color="error"
                class="btnActions"
                @click="approveApplication(false, application)"
              >
                <v-icon class="mx-3">thumb_down_alt </v-icon>
                reject
              </v-btn>
            </div>
            <div class="mx-2">
              <v-btn
                color="success"
                class="btnActions"
                @click.prevent="approveApplication(true, data)"
              >
                <v-icon class="mx-3">thumb_up_alt</v-icon>
                Approve
              </v-btn>
            </div>
          </v-card>
        </v-card>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-card flat>
                <v-card-title class=""> Leave Type </v-card-title>
                <v-card-text>
                  <div class="d-flex justify-start">
                    <div>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ leaveType ? leaveType.description : "N/A" }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <div>
                              <v-chip
                                small
                                label
                                :color="
                                  leaveType
                                    ? leaveType.in_active
                                      ? 'red darken-1'
                                      : 'green accent-3'
                                    : 'N/A'
                                "
                              >
                                {{
                                  leaveType
                                    ? leaveType.in_active
                                      ? "InActive"
                                      : "Active"
                                    : "N/A"
                                }}
                              </v-chip>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div class="mx-3">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Carry Forward
                            <v-avatar
                              class="mx-2"
                              size="25"
                              :color="
                                leaveType
                                  ? leaveType.carry_forward
                                    ? 'green accent-3'
                                    : 'red darken-1'
                                  : 'N/A'
                              "
                            >
                              <v-icon size="15" color="white">
                                {{
                                  leaveType
                                    ? leaveType.carry_forward
                                      ? "done"
                                      : "mdi-close"
                                    : "N/A"
                                }}
                              </v-icon>
                            </v-avatar>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >{{
                              leaveType
                                ? leaveType.max_carry_forward_days
                                : "N/A"
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text>
                  <div class="d-flex justify-start">
                    <div>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Handover Status
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <div>
                              <v-chip
                                small
                                label
                                :color="buttonStyles(application)"
                              >
                                {{ Accept(application) }}
                              </v-chip>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                    <div class="mx-3">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            HandOver Comment
                          </v-list-item-title>
                          <v-list-item-subtitle>{{
                            application.handoverComment
                              ? application.handoverComment
                              : "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card flat>
                <v-card-title> Leave Period </v-card-title>
                <v-card-text>
                  <LeaveDuration
                    :data="application"
                    :previewApplication="true"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <v-row no-gutters>
                <v-col cols="12" md="6">
                  <v-card flat>
                    <v-card-title> Hand Over to </v-card-title>
                    <v-card-text v-if="HandOverPeopleDetails.length !== 0">
                      <v-list-item
                        v-for="(handoverperson, i) in HandOverPeopleDetails"
                        :key="i"
                      >
                        <v-list-item-avatar color="cyan accent-2" size="50">{{
                          handoverperson !== undefined
                            ? handoverperson[0].number
                            : "N/A"
                        }}</v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{
                              handoverperson
                                ? handoverperson[0].first_name
                                : "N/A"
                            }}

                            <v-chip small label color="light-blue lighten-4">
                              {{
                                handoverperson
                                  ? handoverperson[0].job_title
                                  : "N/A"
                              }}
                            </v-chip>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              handoverperson ? handoverperson[0].email : "N/A"
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title> Comments </v-list-item-title>
                          <v-list-item-subtitle>{{
                            application !== undefined
                              ? application.comments
                              : "N/A"
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card flat>
                    <v-card-title class="text-lg-h6">
                      Leave Attachment
                    </v-card-title>
                    <v-card-text>
                      <div
                        v-if="
                          leaveAttachment &&
                          (leaveAttachment[0] === '' ||
                            leaveAttachment[0] === undefined)
                        "
                      >
                        <v-img
                          src="@kinetics254/cassandra-base/assets/image404.png"
                          style="height: 20%; width: 30%"
                        ></v-img>
                      </div>
                      <div v-else>
                        <v-btn
                          text
                          color="primary"
                          style="height: 20%; width: 30%"
                          elevation="0"
                          v-if="base64Ext === 'pdf' || isImage"
                          :to="{
                            name: 'LeaveAttachmentPreview',
                            params: { code: this.application.application_code },
                          }"
                        >
                          <v-icon size="100" color="primary"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          v-else
                          @click="
                            downloadLink(
                              leaveAttachment ? leaveAttachment[0] : ''
                            )
                          "
                          style="height: 20%; width: 30%"
                          elevation="0"
                        >
                          <v-icon size="100" color="primary"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <ApprovalEntries :approvalEntries="approvalEntries" class="mt-3" />
          </v-row>
        </v-card-text>
        <v-btn
          v-if="
            application.status === 'Released' || application.status === 'Posted'
          "
          @click="getLeaveSheet(application.application_code)"
          rounded
          style="position: fixed; right: 5%; bottom: 12%"
          color="primary"
        >
          <v-icon class="mx-3">print</v-icon>
          Leave Sheet
        </v-btn>
        <!-- {{ application }} -->
        <v-btn
          v-if="
            application.status === 'Review' || application.status === 'Posted'
          "
          @click="getLeaveApplicationReport(application.employee_no)"
          rounded
          style="position: fixed; right: 20%; bottom: 12%"
          color="success"
        >
          <v-icon class="mx-3">print</v-icon>
          Leave Application Report
        </v-btn>
      </v-card>
    </v-container>
    <ViewAttachment
      v-model="Status"
      :Image="leaveAttachment ? leaveAttachment[0] : ''"
      :ShowDialog="Status"
    />
    <LeaveSheet />
    <LeaveApplicationReport />
    <Approve />
  </div>
</template>
<style>
.timeline1.v-timeline:before {
  width: 5px;
}
</style>
<script>
import LeaveDuration from "../components/LeaveDuration";
import ViewAttachment from "../components/ViewAttachment";
import { SetupMixin } from "../../../mixins";
import AppBar from "../components/AppBar";
import TopBar from "../components/TopBar";
import LeaveSheet from "../components/LeaveSheet";
import LeaveMethods from "../LeaveMethods";
import Approve from "../components/Approve";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import ApprovalEntries from "./ApprovalEntries.vue";
import LeaveApplicationReport from "../components/LeaveApplicationReport.vue";
export default {
  name: "PreviewApplication",
  mixins: [SetupMixin, FileMixin],
  components: {
    LeaveDuration,
    ApprovalEntries,
    ViewAttachment,
    AppBar,
    TopBar,
    LeaveSheet,
    Approve,
    LeaveApplicationReport,
  },
  data: function () {
    return {
      items: [1, 2, 3],
      Status: false,
      counter: 1,
      path: this.$router.currentRoute.meta.breadcrumb,
      base64Ext: "",
    };
  },
  created() {
    this.getApplication(this.$route.params);
    this.getApprovalApplications();
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.getSetup();
      v.$store.dispatch(
        "leave/getHandoverPeople",
        v.$route.params.application_code
      );
    });
  },
  watch: {
    application() {
      if (this.application.application_code !== undefined) {
        this.getAttachment(this.application);
      }
    },
    leaveAttachment: {
      handler(n, o) {
        if (n[0] !== "") {
          this.getMimeType(n[0]);
        }
      },
    },
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    preview: function () {
      return this.$store.getters["leave/leaveGetter"]("preview");
    },
    leaveAttachment: function () {
      return this.$store.getters["leave/leaveGetter"]("leaveAttachment");
    },
    isImage() {
      return /png|jpeg|xml|gif|svg/.test(this.base64Ext);
    },
    approvalEntries: function () {
      return this.application.approvalEntries
        ? this.application.approvalEntries.filter((appEntry) => {
            if (
              appEntry.document_no === this.application.application_code &&
              appEntry.status !== "Canceled"
            ) {
              const approver = this.leaveEmployeeAll.filter(
                (approver) => approver.user_id === appEntry.approver_id
              )[0];
              appEntry.approver = approver;
              return appEntry;
            }
          })
        : [];
    },

    isApproval: function () {
      return this.leaveEmployee[0] !== undefined
        ? this.leaveEmployee[0].is_approver
        : false;
    },
    handoverRequired: function () {
      return this.$store.getters["leaveSetup/setupGetter"]("handoverSetup")
        .handover;
    },

    application: function () {
      return this.$store.getters["leave/leaveGetter"]("application");
    },
    leaveType: function () {
      return this.leaveTypesAll.filter(
        (type) => type.code === this.application.leave_code
      )[0];
    },

    employee: function () {
      return this.isApproval
        ? this.leaveEmployeeAll
            .filter((employee) => employee.number === this.userData.employee)
            .shift()
        : this.leaveEmployeeAll
            .filter(
              (employee) => employee.number === this.application.employee_no
            )
            .shift();
    },
    handoverperson: function () {
      return this.leaveEmployeeAll
        .filter((employee) => employee.number === this.application.hand_over_to)
        .shift();
    },

    appEntries: function () {
      return (
        this.application.approvalEntries.filter((appEntry) => {
          return (
            appEntry.approver_id === this.employee.user_id &&
            appEntry.status === "Open"
          );
        }).length !== 0
      );
    },

    approveRejectbutton: function () {
      const data =
        this.application.status === "Review" &&
        this.isApproval &&
        this.appEntries;
      return data;
    },
    Sendaprovalbutton: function () {
      const data = this.application.status === "Open";
      return data;
    },
    handoverpeople: function () {
      return this.$store.getters["leave/leaveGetter"]("handoverPeople");
    },
    HandOverPeopleDetails: function () {
      const result = this.handoverpeople.map((f) => {
        return this.leaveEmployeeAll.filter((d) => d.number === f.handoverTo);
      });

      return result;
    },
  },
  methods: {
    ...LeaveMethods,

    Accept: function (val) {
      if (val.acceptHandover === true) {
        return "Accepted";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "Rejected";
        }
      }
    },
    buttonStyles(val) {
      if (val.acceptHandover === true) {
        return "success";
      } else {
        if (val.acceptHandover === false && val.handoverComment !== "") {
          return "error";
        }
      }
    },
    getMimeType(base64) {
      helpers.getFileType(base64).then((res) => {
        this.mimeType = res.mime;
        this.base64Ext = res.ext;
      });
    },
    approverperson: function () {
      return this.leaveEmployeeAll.filter(
        (e) => e.user_id === this.ApprovalEntries
      );
    },
  },
};
</script>
