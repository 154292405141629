import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import moment from "moment/moment";
class PettyCashMethods {
  methods() {
    return {
      pettyCashForm: function () {
        if (!this.isValid) {
          this.$refs.pettyCashForm.validate();
        } else {
          // post data
          delete this.formData.total_amount;
          this.formData.attachments = [];
          Array.prototype.push.apply(
            this.formData.attachments,
            this.attachmentBase64
          );
          let data = { ...this.formData };
          data.payee_name = `${this.employee.first_name} ${this.employee.last_name}`;
          data = this.updateLines(data);
          delete data.totalAmount;
          this.$route.query.edit
            ? this.$store.dispatch("pettycash/editPettyCash", data)
            : this.$store.dispatch("pettycash/submitPettyCash", data);
        }
      },
      updateLines: function (data) {
        data.employee_no = this.employee.number;
        data.employee_name = `${this.employee.first_name} ${this.employee.last_name}`;
        data.pettyCashLinesAPI.map(async (line) => {
          line.amount = parseFloat(line.amount);
          delete line.id;
          if (!this.$route.query.edit) {
            delete line.document_no;
          }
          return line;
        });
        return data;
      },
      mapPettycashLines: function (data) {
        data.pettyCashLinesAPI.map(async (line) => {
          line.amount = parseFloat(line.amount);
          delete line.attachment;
          delete line.id;
          if (!this.$route.query.edit) {
            delete line.document_no;
          }
          delete line.attachmentName;
          if (line.attachmentBlob) {
            delete line.attachmentBlob;
          }
          return line;
        });
      },

      deletePettyCash: function () {
        this.$confirm.show({
          text: "Are you sure you want to delete this request?",
          onConfirm: () => {
            this.$store.dispatch("pettycash/deletePettyCash", {
              ...this.formData,
            });
          },
        });
      },
      getColor(status) {
        return helpers.getColor(status);
      },

      getDateFormat(date) {
        return helpers.getDateFormat(date);
      },

      addItem: function (item) {
        this.isEditLines = false;
        this.pettycashLine.description = item.description;
        this.pettycashLine.expense_code = item.code;
        this.itemDialog = true;
        const fileInput = this.$refs.fileInput;
        if (fileInput !== undefined) fileInput.reset();
      },

      editExpenseCode: async function () {
        this.pettycashLine.posting_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
        this.formData.pettyCashLinesAPI = this.formData.pettyCashLinesAPI.map(
          (item) => {
            if (item.expense_code === this.pettycashLine.expense_code)
              item = { ...this.pettycashLine };
            return item;
          }
        );
        this.pettycashLine = {};
        this.itemDialog = false;
      },

      toBase64: (file) =>
        new Promise((resolve, reject) => {
          if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          }
        }),
      downloadLink(fileLink) {
        const a = document.createElement("a");
        a.href = process.env.VUE_APP_FILE_API + fileLink;
        a.download = process.env.VUE_APP_FILE_API + fileLink;
        a.click();
      },
      autosaveExpenseItem: async function () {
        if (this.expenseItemIndex > -1) {
          this.expenseItem.code = this.expenseItem.expense_code;
          if (this.$route.query.edit) {
            this.expenseItem.document_no = this.formData.no;
            this.expenseItem.document_type = this.formData.document_type;
          } else {
            this.expenseItem.posting_date = moment(new Date()).format(
              "YYYY-MM-DD"
            );
            delete this.expenseItem.document_no;
            delete this.expenseItem.document_type;
          }
          Object.assign(
            this.expenseItemsList[this.expenseItemIndex],
            this.expenseItem
          );
        }
      },
      displayConditionally(item) {
        if (item !== null) {
          if ("line_no" in item) {
            if (item.line_no === this.expenseItem.line_no) {
              return true;
            }
            return false;
          } else if ("id" in item) {
            if (item.id === this.expenseItem.id) {
              return true;
            }
          }
        }
        return false;
      },
      editItem(item) {
        this.expenseItem = { ...item };
        this.expenseItemIndex = this.expenseItemsList.indexOf(item);
        this.expenseItem = Object.assign({}, { ...item });
      },
      deleteLine: function (item) {
        if (item.line_no) {
          this.$confirm.show({
            text: "Are you sure you want to remove this Item?",
            onConfirm: () => {
              this.$store.dispatch("pettycash/deletePettyCashLines", item);
              for (const i in this.formData.pettyCashLinesAPI) {
                if (
                  this.formData.pettyCashLinesAPI[i].line_no === item.line_no
                ) {
                  this.formData.pettyCashLinesAPI.splice(i, 1);
                  break;
                }
              }
            },
          });
        } else {
          for (const i in this.formData.pettyCashLinesAPI) {
            if (
              this.formData.pettyCashLinesAPI[i].expense_code ===
              item.expense_code
            ) {
              this.formData.pettyCashLinesAPI.splice(i, 1);
              break;
            }
          }
        }
      },
      closeDialog: function (val) {
        this.itemDialog = val;
      },
      close() {
        setTimeout(() => {
          this.expenseItem = Object.assign({}, this.defaultExpenseItem);
          this.expenseItemIndex = -1;
        }, 300);
      },
      addNew() {
        const addObj = Object.assign({}, this.defaultExpenseItem);
        addObj.id = this.expenseItemsList.length + 1;
        this.expenseItemsList.unshift(addObj);
        this.editItem(addObj);
        if (this.$route.query.edit) {
          this.expenseItem.document_no = this.formData.no;
          this.expenseItem.document_type = this.formData.document_type;
        }
        this.expenseItem.shortcutDimension1Code =
          this.formData.shortcutDimension1Code;
        this.expenseItem.shortcutDimension2Code =
          this.formData.shortcutDimension2Code;
        this.expenseItem.shortcutDimension3Code =
          this.formData.shortcutDimension3Code;
        this.expenseItem.shortcutDimension4Code =
          this.formData.shortcutDimension4Code;
      },
      selectExpenseCode: function () {
        var values = this.expenseItems.map(function (o) {
          return o.code;
        });
        var index = values.indexOf(this.expenseItem.expense_code);
        this.expenseItem.to = "";
        this.expenseItem.from = "";
        this.expenseItem.amount = 1;
        this.expenseItem.transportCode = "";
        const travel = this.transportRates.find(
          ({ expenseCode }) => expenseCode === this.expenseItem.expense_code
        );
        if (travel !== undefined) {
          this.expenseItem.transportCode = travel ? travel.code : "";
          this.expenseItem.to = travel ? travel.to : "";
          this.expenseItem.from = travel ? travel.from : "";
          this.expenseItem.amount = travel
            ? travel.rate
            : this.expenseItem.amount;
        }
        this.expenseItem.description = this.expenseItems[index].description;
      },
      addDimensions(item) {
        delete item.document_date;
        delete this.document_type;
        this.expenseItem = Object.assign({}, { ...item });
        this.itemDialog = true;
      },
      updateDimensions(val) {
        if ("line_no" in val) {
          Object.assign(
            this.expenseItemsList[
              this.expenseItemsList.findIndex(
                (el) => el.line_no === val.line_no
              )
            ],
            this.expenseItem
          );
        } else if ("id" in val) {
          Object.assign(
            this.expenseItemsList[
              this.expenseItemsList.findIndex((el) => el.id === val.id)
            ],
            this.expenseItem
          );
        }
      },
      sendForApproval: function (method) {
        if (!this.isValid) {
          this.$refs.pettyCashForm.validate();
        } else {
          this.formData.attachments = [];
          Array.prototype.push.apply(
            this.formData.attachments,
            this.attachmentBase64
          );
          delete this.formData.total_amount;
          let data = {
            ...this.formData,
            method,
          };
          data = this.updateLines(data);
          const params = {
            title: "Send Approval Request",
            text: "Are you sure you want to send this request for approval?",
            onConfirm: () => {
              this.$store.dispatch("pettycash/saveAndSendforApproval", data);
            },
          };
          this.$confirm.show(params);
        }
      },
      cancelApprovalRequest: function () {
        const data = {
          headerNo: this.formData.no,
        };
        const params = {
          title: "Cancel Approval Request",
          text: "Are you sure you want to cancel this request?",
          onConfirm: () => {
            this.$store.dispatch("pettycash/cancelApprovalRequest", {
              ...data,
            });
          },
        };
        this.$confirm.show(params);
      },
      removeBase64(item) {
        if (item === "all") {
          this.attachmentBase64 = this.attachmentBase64.filter(
            (item) => typeof item["lineNo"] !== "undefined"
          );
        } else if (typeof item["lineNo"] === "undefined") {
          const itemIndex = this.attachmentBase64.findIndex(
            (el) => el.fileName === item.fileName
          );
          const itemBlobIndex = this.attachmentBase64.findIndex(
            (file) => file.name === item.fileName
          );
          this.attachmentBase64.splice(itemIndex, 1);
          this.attachmentBlobs.splice(itemBlobIndex, 1);
        } else {
          this.$store.dispatch("pettycash/deleteAttachment", item);
        }
      },
    };
  }
}

export default new PettyCashMethods().methods();
