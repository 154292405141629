<template>
  <v-data-table :headers="headers" :items="supervisorReviews">
    <template v-slot:item.id="{ item }">
      <span class="supervisor-review-table">{{ item["code"] }}</span>
    </template>
    <template v-slot:item.currentStage="{ item }">
      <span class="supervisor-review-table">{{ item["currentStage"] }}</span>
    </template>
    <template v-slot:item.employeeScore="{ item }">
      <span class="supervisor-review-table">{{ MyScore(item) }}%</span>
    </template>
    <template v-slot:item.supervisorScore="{ item }">
      <span class="supervisor-review-table">{{ SupervisorScore(item) }}%</span>
    </template>
    <template v-slot:item.overalScore="{ item }">
      <span class="supervisor-review-table">{{ OverallScore(item) }}%</span>
    </template>
    <template v-slot:item.documentStatus="{ item }">
      <v-chip
        v-show="item.documentStatus"
        small
        :color="getColor(item.documentStatus).color"
        dark
      >
        <span class="supervisor-review-table">{{
          item["documentStatus"]
        }}</span>
      </v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn-toggle>
        <!-- <v-btn
                  small
                  color="teal darken-1"
                  class="mx-2 white--text"
                  tile
                  link
                  v-if="item.documentStatus === 'Reviewed'"
                  :to="{ name: 'AppraisalReport', params: { code: item.code }}" >
                  report
                </v-btn> -->
        <v-btn
          small
          color="primary"
          class="mx-2 white--text"
          tile
          link
          v-if="item.documentStatus === 'Reviewed'"
          @click="downloadEmployeeReport(item.code)"
        >
          <v-icon left color="white"> mdi-download </v-icon>
          report
        </v-btn>
        <v-btn
          small
          color="primary"
          tile
          :disabled="
            (item.documentStatus === 'Reviewed' ||
              item.periodAPI.closed === true) &&
            tab !== '2nd'
          "
          :to="{
            name: 'Appraisal Card',
            params: { code: item.code },
          }"
        >
          {{
            tab === "2nd" && item.documentStatus === "Reviewed"
              ? "comment"
              : "Appraise"
          }}
          <v-icon right color="white">mdi-arrow-right</v-icon>
        </v-btn>
        <!-- v-if="hasTraining" -->
        <v-btn
          small
          color="primary"
          tile
          :disabled="
            item.documentStatus === 'Reviewed' || item.periodAPI.closed === true
          "
          :to="{
            name: 'TrainingCard',
          }"
          class="ml-2"
        >
          Training Request
          <v-icon right color="white">mdi-arrow-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-data-table>
</template>

<script>
import AppraisalMixin from "@/packages/cassandra-appraisal/modules/appraisalv3/AppraisalMixin";
export default {
  name: "AppraisalSupervisorReviewTable",
  mixins: [AppraisalMixin],
  props: { supervisorReviews: Array, tab: String },
  data() {
    return {
      headers: [
        { text: "Code", align: "", sortable: true, value: "id" },
        {
          text: "Employee Name",
          align: "",
          sortable: true,
          value: "employeeName",
        },
        { text: "Stage", align: "", sortable: true, value: "currentStage" },
        {
          text: "Employee Score",
          align: "center",
          sortable: true,
          value: "employeeScore",
        },
        {
          text: "Supervisor Score",
          align: "center",
          sortable: true,
          value: "supervisorScore",
        },
        {
          text: "Final Score",
          align: "center",
          sortable: true,
          value: "overallScore",
        },
        { text: "Status", align: "", sortable: true, value: "documentStatus" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  computed: {
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    hasTraining() {
      return this.settings
        ? JSON.parse(this.settings.site_installed_packages).find(
            (item) => item.name === "Training"
          ) === undefined
          ? false
          : true
        : false;
    },
  },
  methods: {
    async downloadFile() {
      this.$store.dispatch("appraisal/getSupervisorReport", {
        reviewerID: this.userData.employee,
      });
    },
    async downloadEmployeeReport(item) {
      await this.$store.dispatch("appraisal/getAppraisalReport", {
        reviewNo: item,
      });
    },
  },
};
</script>
