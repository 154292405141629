<template>
  <!-- dialog to edit kpis -->
  <v-dialog v-model="dialog" width="80%">
    <v-card>
      <v-card-title class="">
        {{ editing ? "Edit" : "Add" }} Group
        <v-spacer></v-spacer>
        <v-btn fab outlined color="red" @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="entity">
        <v-form @submit.prevent="submitForm">
          <v-row dense justify="space-between" class="mt-2">
            <!-- <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                disabled
                v-model="editedData.groupID"
                label="Group Code"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                disabled
                v-model="editedData.templateID"
                label="Template"
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                dense
                v-model="editedData.groupDescription"
                label="Group Description"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field
                outlined
                dense
                v-model="editedData.Weight"
                label="Weight"
              ></v-text-field>
            </v-col> -->
          </v-row>

          <v-btn rounded type="submit" color="primary">Save Group</v-btn>
        </v-form>
      </v-card-text>
      <v-card-text v-else> Error while Loading the Form </v-card-text>
    </v-card>
  </v-dialog>
  <!-- end -->
</template>
<script>
export default {
  name: "ObjectiveAddEditDialog",
  props: {
    groupDialog: Boolean,
    editing: Boolean,
    entity: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      editedData: { ...this.entity },
    };
  },
  mounted() {
    this.editedData = { ...this.entity };
  },
  computed: {
    dialog: {
      get() {
        return this.groupDialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },
  methods: {
    submitForm() {
      //emit fn save
      this.$emit(this.editing ? "edit-group" : "add-group", this.editedData);
    },
  },
  watch: {
    entity: {
      handler(newValue) {
        this.editedData = { ...newValue };
      },
      deep: true, // handle changes in {.{...}}
      immediate: true,
    },
  },
};
</script>
