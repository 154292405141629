<template>
  <v-dialog v-model="dialog" width="80%">
    <v-card class="mx-auto mt-10" flat>
      <v-card-title>
        KPIs <v-spacer />

        <span class="ml-0 text-body-1">
          Application:
          <span class="font-weight-bold">{{ routeParams }}</span></span
        >
        <v-spacer />
        <span class="mr-2 text-body-1"> Status:</span>
        <v-chip dark :color="getStatusColor(currentApplication.status).color">
          <span
            class="black--text"
            v-if="currentApplication.status === 'Pending Approval'"
            >{{ currentApplication.status }}</span
          >
          <span v-else>{{ currentApplication.status }}</span>
        </v-chip>
        <v-chip color="primary">
          <span class="white--text">{{ currentApplication.name }}</span>
          <span class="white--text"> - </span>
          <span class="white--text">{{ currentApplication.employeeNo }}</span>
        </v-chip>
      </v-card-title>
      <v-card-title>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <!-- highest level is Objectives -->
        <v-row>
          <!-- Objective Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">{{
                  kpiObjectiveCaption ? kpiObjectiveCaption : "Objectives"
                }}</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="employeeObjectives"
                >
                  {{ employeeObjectives.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group v-if="employeeObjectives">
                <v-list-item
                  v-for="obj in employeeObjectives"
                  :key="obj.objectiveID"
                  @click="setKpi(obj)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ obj.objectiveID }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon color="green lighten-2"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-tooltip bottom max-width="32%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-subtitle
                          class="list-group-item"
                          v-bind="attrs"
                          v-on="on"
                          v-text="obj.description"
                        ></v-list-item-subtitle>
                      </template>
                      <span>{{ obj.description }}</span>
                    </v-tooltip>
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon>mdi-menu-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />

          <!-- KPI Listing -->
          <v-col cols="12" md="4" ref="listedIndicators">
            <v-list subheader two-line dense>
              <v-subheader>
                <span class="subheader-title">Key Performance Indicators</span>
                <v-spacer />
                <v-chip
                  x-small
                  dark
                  class="deep-orange"
                  v-if="intendedObjSelection && intendedObjSelection.indicators"
                >
                  {{ intendedObjSelection.indicators.length }}
                </v-chip>
              </v-subheader>
              <v-list-item-group
                v-if="intendedObjSelection && intendedObjSelection.indicators"
              >
                <v-list-item
                  v-for="kpi in intendedObjSelection.indicators"
                  :key="kpi.kpiCode"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ kpi.kpiCode }}
                      <span v-if="$vuetify.breakpoint.smAndDown">
                        <v-icon color="green lighten-2"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                    <v-tooltip bottom max-width="32%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-subtitle
                          class="list-group-item"
                          v-bind="attrs"
                          v-on="on"
                          v-text="kpi.kpiDescription"
                        ></v-list-item-subtitle>
                      </template>
                      <span>{{ kpi.kpiDescription }}</span>
                    </v-tooltip>

                    <v-list-item-subtitle class="list-group-item">
                      <b>weight</b> {{ kpi.weight }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="list-group-item">
                      <b>Target </b>{{ kpi.target }}</v-list-item-subtitle
                    >
                  </v-list-item-content>

                  <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                    <!-- display only when kpi is rated -->
                    <v-btn icon>
                      <v-icon color="green lighten-2">mdi-check-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <!-- KPI edit btn -->
                  <v-btn
                    small
                    text
                    class="mx-1"
                    fab
                    @click="editKpisHandler(kpi)"
                  >
                    <v-icon>mdi-pencil-circle</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
            <v-divider />
          </v-col>
          <v-divider v-else vertical />
        </v-row>
        <!-- end -->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn class="pa-2" @click="GoBack()" color="error">
          <v-icon left>mdi-arrow-left</v-icon>
          Go Back
        </v-btn>
        <v-spacer />
        <v-btn
          @click="commentDialog = true"
          class="mr-2"
          color="error"
          depressed
        >
          Reject
        </v-btn>
        <v-btn
          @click="SubmitWeights()"
          color="green"
          class="ma-2 white--text mr-2"
          depressed
        >
          Approve
        </v-btn>
      </v-card-actions>

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="commentDialog"
        max-width="600"
      >
        <v-card>
          <v-toolbar color="red" dark> Reject Reason </v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-3">
              <v-textarea
                clearable
                v-model="commentText"
                outlined
                clear-icon="mdi-close-circle"
                label="Reject Reason"
                model-value="This is clearable text."
              ></v-textarea>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="commentDialog = false">Close</v-btn>
            <v-spacer />
            <v-btn @click="Reject()" class="mr-2" color="error" depressed>
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <EditTemplateDialog
        :editTemplateDialog="editTemplateDialog"
        :entity="objectEntity"
        :editing="isEditing"
        @close-dialog="closeDialog"
        @edit-item="editKpi"
        ref="editTemplateDialog"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import { helper } from "../../../../cassandra-base/utilities";
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
import EditTemplateDialog from "./EditTemplateDialog.vue";

export default {
  name: "AppraisalApprovalCard",
  mixins: [RouterMixin, FileMixin],
  components: { EditTemplateDialog },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getObjectiveCodes");
      if (atob(v.$route.params.documentNo)) {
        v.$store.dispatch(
          "appraisal/getLinesForApplication",
          atob(v.$route.params.documentNo)
        );
        v.$store.dispatch(
          "appraisal/getApplication",
          atob(v.$route.params.documentNo)
        );
        v.$store.dispatch("appraisal/getPendingApprovals");
        v.$store.dispatch(
          "appraisal/getApprovalEntries",
          atob(v.$route.params.documentNo)
        );
      }
    });
  },
  data: () => ({
    selectedKpi: null,
    selectedPersp: null,
    selectedObject: null,
    intendedObjSelection: null,
    intendedPerspSelection: null,
    unsaved: false,
    status: "",
    prevRoute: null,

    dialog: true,
    commentDialog: false,
    search: "",
    commentText: "",
    values: [],
    applicationLines: [],
    expanded: [],

    //update weights
    editTemplateDialog: false,
    isEditing: true,
    objectEntity: {},
  }),

  computed: {
    isDataLoaded() {
      return this.$store.getters["appraisal/appraisalGetter"]("isDataLoaded");
    },
    userData() {
      return AuthService.user;
    },
    routeParams() {
      return atob(this.$route.params.documentNo);
    },
    //custom objective caption
    kpiObjectiveCaption() {
      return this.userData.captions.kpiObjective_caption;
    },
    currentApplication() {
      return this.$store.getters["appraisal/appraisalGetter"]("application");
    },
    employeeGroups() {
      return this.currentApplication
        ? this.currentApplication?.employeeGroups
        : [];
    },
    employeeObjectives() {
      return this.currentApplication
        ? this.currentApplication?.employeeAppObjectives
        : [];
    },
    employeeKpis() {
      return this.currentApplication.employeeApplicationLines.map(
        ({
          documentNo,
          kpiCode,
          kpiDescription,
          lineNo,
          objectiveCode,
          groupCode,
          target,
          weight,
          global,
        }) => ({
          documentNo,
          kpiCode,
          kpiDescription,
          lineNo,
          objectiveCode,
          groupCode,
          target,
          weight,
          global,
        })
      );
    },
    approval() {
      const approvals =
        this.$store.getters["Approvals/approvalGetters"]("approvalEntries");
      return (
        approvals.data.filter((el) => {
          return el.documentNo === this.routeParams;
        })[0] || {}
      );
    },
    weightTotal() {
      return this.currentApplication.employeeApplicationLines.reduce(
        (a, b) => a + b.weight,
        0
      );
    },
    targetTotal() {
      return this.currentApplication.employeeApplicationLines.reduce(
        (a, b) => a + b.target,
        0
      );
    },
  },

  methods: {
    gotoKpiListing() {
      if (this.$vuetify.breakpoint.smAndDown) {
        const el = this.$refs.listedIndicators;
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },

    setKpi: function (kpi) {
      if (!kpi) {
        return;
      }

      this.intendedObjSelection = { ...kpi };

      this.intendedObjSelection.indicators = this.employeeKpis.filter(
        (kpi) =>
          kpi.objectiveCode === this.intendedObjSelection.objectiveID &&
          kpi.kpiCode !== ""
      );

      if (
        JSON.stringify({ ...this.selectedKpi }) !==
        JSON.stringify({ ...this.selectedObject })
      ) {
        this.unsaved = true;
        return;
      }

      this.selectedKpi = {
        ...kpi,
      };

      this.selectedObject = { ...this.selectedKpi };
      this.$nextTick(() => {
        Event.$emit("parentAction", "employeeForm");
        Event.$emit("parentAction", "supervisorForm");
      });
    },

    setObjective: function (obj) {
      this.intendedPerspSelection = { ...obj };
      this.intendedPerspSelection.objectives = this.employeeObjectives.filter(
        (appObjectives) =>
          appObjectives.groupID === this.intendedPerspSelection.groupID
      );

      this.selectedPersp = {
        ...obj,
      };

      this.selectedObject = { ...this.selectedKpi };
      this.$nextTick(() => {
        Event.$emit("parentAction", "employeeForm");
        Event.$emit("parentAction", "supervisorForm");
      });
    },

    discard: function () {
      this.selectedKpi = { ...this.selectedObject };
      this.unsaved = false;

      this.setKpi(this.intendedObjSelection);
    },

    SubmitWeights() {
      const newApplicationLines = this.applicationLines.map(
        ({ indicators, ...rest }) => rest
      );
      const data = {
        documentNo: this.routeParams,
        employeeApplicationLines: newApplicationLines,
      };

      this.approval.senderID = this.currentApplication.employeeNo;
      const allData = {
        KPIData: data,
        approveData: this.approval,
        applicationNo: this.routeParams,
      };

      this.$store.dispatch("appraisal/addKPIWeights", allData);
    },
    GoBack() {
      this.$router.push({ name: "ApprovalList" });
    },

    Reject() {
      this.commentDialog = true;
      this.approval["reason"] = this.commentText;
      this.$store.dispatch("appraisal/rejectApplication", this.approval);
      this.commentDialog = false;
      this.$router.push({ name: "ApprovalList" });
    },
    getStatusColor: function (status) {
      return helper.getColor(status);
    },
    /** fn edit KPI's*/
    editKpisHandler: function (kpi) {
      this.isEditing = true;
      this.objectEntity = { ...kpi };
      this.editTemplateDialog = true;
    },
    editKpi(updatedItem) {
      if (this.isEditing) {
        const indicatorIndex = this.intendedObjSelection.indicators.findIndex(
          (indicator) => indicator.kpiCode === updatedItem.kpiCode
        );

        if (indicatorIndex !== -1) {
          updatedItem.documentNo = this.currentApplication.no;
          updatedItem.groupCode = this.intendedObjSelection.groupID ?? "";
          updatedItem.isUpdate = true;

          this.$store.dispatch("appraisal/createEditKpiHandler", updatedItem);

          this.closeDialog();
        }
      }
    },
    //close dialog
    closeDialog() {
      this.editTemplateDialog = false;
    },
  },

  watch: {
    currentApplication() {
      this.setKpi(this.employeeObjectives[0]);
    },
  },
};
</script>
<style scoped>
@import "../../appraisalv1/styles/appraisal.css";
</style>
