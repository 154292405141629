import call from "@kinetics254/cassandra-base/service/http";
import constants from "./constants";

export default {
  namespaced: true,
  state: {
    periods: [],
    selectedPeriod: "",
    reviews: [],
    templates: {},
    isDataLoaded: false,

    hrEmployeeWithJds: {},
    appraisalGuideline: [],
    //hrEmployee: [],
    jobs: [],
    //rating scale
    ratingScale: [],
    //applications
    applications: [],
    application: {},
    appLines: [],
    objectiveCodes: [],
    Acceptance: {},
    archivedReviewDocument: [],
    reviewDocument: undefined,

    //reviews
    supervisorReviews: [],
    kpi: [],
    review: false,
    isReviewer: false,
    reviewObj: [],
    appraisalReport: "",
    supervisorReport: "",
    secondSupervisorReviews: [],
    currentReview: {},

    pendingApprovals: [],
    approvalEntries: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },

    SET_REVIEW: (state, payload) => {
      state.review = payload;
    },
  },
  getters: {
    appraisalGetter: (state) => (value) => state[value],
  },
  actions: {
    getPeriods: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.periods)
        .then((res) => {
          commit("MUTATE", { state: "periods", data: res.data.data });
          commit("MUTATE", { state: "isDataLoaded", data: true });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getTemplates: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.templates)
        .then((res) => {
          commit("MUTATE", { state: "templates", data: res.data.data[0] });
          commit("MUTATE", { state: "isDataLoaded", data: true });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.reviews)
        .then((res) => {
          commit("MUTATE", { state: "reviews", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    setSelectedPeriod: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedPeriod", data: payload.code });
    },

    sendForAcceptance: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.acceptance, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "acceptance", data: res.data.data });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    //fn () get hrEmployee &(JDS)
    getHrEmployeeWithJDS: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.JDS(data))
        .then((res) => {
          commit("MUTATE", { state: "hrEmployeeWithJds", data: res.data.data });
          commit("MUTATE", { state: "isDataLoaded", data: true });

          const hrEmp = res.data.data[0];
          //console.log("empl", hrEmp);

          dispatch("getJobs", {
            code: hrEmp.job?.code,
            grade: hrEmp.employeeGrade,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getJobs: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.jobs(payload))
        .then((res) => {
          commit("MUTATE", { state: "jobs", data: res.data.data });
          commit("MUTATE", { state: "isDataLoaded", data: true });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /* get JDS report*/
    getJobDescriptionsReport: ({ commit }, { data }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getJDSReport, data)
        .then((res) => {
          const jobDescriptionReport = res.data.data;
          // commit("SET_JOB_DESCRIPTION_REPORT", {
          //   open: true,
          //   jobDescriptionReport,
          // });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    //get guidelines
    getAppraisalGuideLine: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.APPRAISALGUIDELINE)
        .then((res) => {
          //@todo update state
          commit("MUTATE", {
            state: "appraisalGuideline",
            data: res.data.data,
          });
          commit("MUTATE", { state: "isDataLoaded", data: true });
          //console.log("guideline", res.data.data);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    //fn () get ratingScale
    getRatingScale: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.ratingScale)
        .then((res) => {
          commit("MUTATE", { state: "ratingScale", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    //fn () save or submit application
    submitAppraisal: ({ commit, dispatch }, payload) => {
      call("post", constants.submitAppraisal, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Appraisal submitted");
          dispatch("getReviews");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    //create appraisal appplication
    createApplication: ({ commit, dispatch }, payload) => {
      //console.log("create application =>", payload);
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.createApplication, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Application created, click to proceed");
          commit("loader/SET_LOADING", false, { root: true });
          //get employee applications
          const app = res.data.data[0];
          dispatch("getApplication", app.no);
          dispatch("getApplications");
          Event.$emit("redirect-acceptance-form");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    /*fn () getApplications*/
    getApplications: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getApplications)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "applications", data: res.data.data });
          commit("MUTATE", { state: "isDataLoaded", data: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    //fn () get single application
    getApplication({ commit }, no) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.SINGLEAPP(no))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "application",
            data: res.data.data[0],
          });
        })
        .catch((err) => {
          Event.$emit(
            "ApiError",
            err?.response ? err?.response?.data?.message : err?.message
          );
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    editApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.editApplication, data)
        .then(() => {
          Event.$emit("ApiSuccess", "Saved Successfully");
          Event.$emit("back");
          dispatch("getApplications");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteApplication, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getApplications");
          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /**fn ()  handlers for groups, objectives and indicators*/
    createEditGroupHandler: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.employeeGroups, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Group updated");
          const app = res.data.data[0];
          dispatch("getApplication", app.documentNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    createEditObjectivesHandler: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.employeeAppObjectives, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "succefully updated objectives");
          const app = res.data.data[0];
          dispatch("getApplication", app.documentNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    createEditKpiHandler: ({ commit, dispatch }, payload) => {
      // console.log("passed kpi payload", payload);
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.appKpis, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "succefully updated kpi");
          const app = res.data.data[0];
          dispatch("getApplication", app.documentNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    //get objectiveCodes
    getObjectiveCodes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.objectiveCodes)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "objectiveCodes", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getLinesForApplication({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getApplicationLines(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "appLines", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deleteApplicationLine: ({ commit, dispatch }, data) => {
      // commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteLine, data)
        .then((res) => {
          //commit("loader/SET_LOADING", false, { root: true });

          Event.$emit("ApiSuccess", res.data.message);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          //commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deleteObjective: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteObjective, payload)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          const { documentNo } = payload;
          dispatch("getApplication", documentNo);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    // approval
    sendForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.sendForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getApplications");
          Event.$emit("ApiSuccess", res.data.message);
          Event.$emit("redirect-applications");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    cancelForApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelForApproval, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getApplication", data.applicationNo);
          Event.$emit(
            "ApiSuccess",
            "Appraisal succesfully canceled for approval"
          );
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    //get archivedReviewDocument
    getArchivedReviewDocument: ({ commit }, params) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.archivedReviewDocument(params))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "archivedReviewDocument",
            data: res.data.data,
          });
        })
        .catch((error) => {
          console.error(error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiError",
            "Sorry, we ran into problems loading this data"
          );
        });
    },
    getReviewDocument({ commit }, params) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.getReviewDocument(params))
        .then((res) => {
          commit("MUTATE", {
            state: "reviewDocument",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    // get all lists
    getPeerReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.supervisorReviews)
        .then((res) => {
          commit("MUTATE", { state: "supervisorReviews", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getSecondPeerReviews: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.secondSupervisorReviews)
        .then((res) => {
          commit("MUTATE", {
            state: "secondSupervisorReviews",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    /* get one record(current review) **/
    getByDocumentNo: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.reviewDocument(data))
        .then((res) => {
          commit("MUTATE", { state: "currentReview", data: res.data.data[0] });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getReviewees: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.reviewees)
        .then((res) => {
          commit("MUTATE", { state: "reviews", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getKpi: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.kpi)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "kpi", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    updateKpi: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.kpi, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "KPI rated");
          dispatch("getByDocumentNo", res.data.data.shift().reviewID);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    approveApplication: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.approve, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Appraisal application succesfully approved"
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addKPIWeights: ({ commit, dispatch }, data) => {
      dispatch("approveApplication", data.approveData);
    },
    updateSupervisorKpi: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.kpi, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "KPI rated");
          dispatch("getByDocumentNo", res.data.data.shift().reviewID);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getReviewObj: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.reviewObj)
        .then((res) => {
          commit("MUTATE", { state: "reviewObj", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getAppraisalReport: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.appraisalReport, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "appraisalReport",
            data: res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getSupervisorReport: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.supervisorReport, payload)
        .then((res) => {
          commit("MUTATE", {
            state: "supervisorReport",
            data: process.env.VUE_APP_FILE_API + res.data.data,
          });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addEmployeeRemarks: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.reviews, payload)
        .then((res) => {
          dispatch("submitAppraisal", {
            reviewNo: res.data.data.code,
            reviewerID: res.data.data.reviewerID,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    reviewAppraisal: ({ commit, dispatch }, data) => {
      call("post", constants.reviewAppraisal, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review submitted");
          dispatch("getPeerReviews");
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    addSupervisorRemarks: ({ commit, dispatch }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("patch", constants.reviews, payload)
        .then((res) => {
          dispatch("reviewAppraisal", {
            reviewNo: res.data.data.code,
            employeeNo: res.data.data.employeeNo,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    acceptReview: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.acceptReview, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review accepted");
          Event.$emit("goToAppraisal");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    rejectReview: ({ commit }, payload) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.employeeRejectReview, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review rejected");
          Event.$emit("goToAppraisal");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    submitComment({ commit, dispatch }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.REVIEWER_COMMENT, payload)
        .then((res) => {
          Event.$emit("ApiSuccess", "Review comment submitted succesfully");
          Event.$emit("closeDialog");
          dispatch("getByDocumentNo", payload.reviewDocNo);
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getPendingApprovals: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.pendingApprovals)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "pendingApprovals",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getApprovalEntries({ commit }, payload) {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.appEntries(payload))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "approvalEntries", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    rejectApplication: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.reject, data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            "Appraisal Application succesfully Rejected"
          );
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
  },
};
