export default {
  imprest: "hrmis/imprest",
  imprestStatistics: "hrmis/imprest/dashboard/statistics",
  pendingImprests: "hrmis/imprest/pending-approvals",
  saveReceipt: "hrmis/imprest/detail",
  getReceipt: "hrmis/imprest/detail",
  deleteImprest: (param) => `hrmis/imprest?${buildQuery(param)}`,
  deleteImage: "hrmis/imprest/detail/delete",
  imprestDetails: (param) => `hrmis/imprest/${param}/details`,
  detailedImprest: (param) => `hrmis/imprest/detail?${buildQuery(param)}`,
  expenseCode: "hrmis/imprest/expense_code",
  dimensions: "hrmis/imprest/dimensions",
  sendForApproval: "hrmis/imprest/sendForApproval",
  modeOfTransport: "hrmis/imprest/modeOfTransport",
  locations: "hrmis/imprest/locations",
  funds: "hrmis/imprest/funds",
  dimensionSpeedKey: "hrmis/imprest/dimensionSpeedKey",
  imprestTypeApi: "hrmis/imprest/imprestTypeApi",
  approveImprest: "hrmis/imprest/approveImprest",
  RejectImprest: "hrmis/imprest/RejectImprest",
  cancelApprovalRequest: "hrmis/imprest/cancelApprovalRequest",
  ReopenDocument: "hrmis/imprest/ReopenDocument",
  deleteAttachment: "hrmis/imprest/attachments/destroy",
  CheckBudget: "hrmis/imprest/CheckBudget",
  expenseItem: "hrmis/imprest/line",
  unitsOfMeasure: "hrmis/imprest/unitsOfMeasure",
  currencies: "hrmis/imprest/currencies",
  ImprestSurrenderDocument: "hrmis/imprest/ImprestSurrender",
  imprestDocument: "hrmis/imprest/imprestDocument",
  imprestAccountedFor: "hrmis/imprest/accountFor",
  rejectWithComment: "hrmis/imprest/reject",
  updateAccounting: "hrmis/imprest/detail/update",
};

function buildQuery(param) {
  const query = new URLSearchParams(param);
  return query.toString();
}
