<template>
  <v-dialog
    persistent
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    width="70%"
  >
    <v-card class="mx-auto">
      <v-card-title>
        <span class="headline">Exit Notice</span>
        <v-spacer />
        <v-btn
          class="secondary mx-2 text-capitalize"
          @click="sendForApproval"
          v-if="$route.params.code && formData.status === 'Open'"
        >
          Send For Approval
        </v-btn>
        <v-btn
          class="teal white--text text-capitalize mx-2"
          v-if="isQuestionnaire"
          link
          :to="questionnairePath"
          target="_blank"
        >
          <v-icon class="mx-2">mdi-plus</v-icon>
          Fill Exit Questionnaire
        </v-btn>
        <div class="mr-4">
          <v-badge
            bordered
            color="success"
            :content="employeeChecklist.count"
            :value="employeeChecklist.count"
            overlap
          >
            <v-btn
              color="primary"
              class="text-capitalize"
              depressed
              @click="checklistDialog = true"
            >
              view checklist
            </v-btn>
          </v-badge>
        </div>
        <v-btn
          class="primary mr-2 text-capitalize"
          @click="downloadClearanceForm"
          v-if="$route.params.code && formData.status === 'Released'"
        >
          View Clearance Form
        </v-btn>
        <v-chip
          v-if="$route.params.code"
          label
          dark
          :color="getColor(formData.status).color"
          class="mr-2"
        >
          {{ formData.status }}
        </v-chip>
        <v-btn
          color="orange"
          class="mr-2"
          dark
          depressed
          v-if="formData.status === 'Pending Approval'"
          @click="cancelSendForApproval"
        >
          <v-icon class="mr-2 mb-1" size="16">mdi-cancel </v-icon>
          cancel approval
        </v-btn>
        <v-btn icon outlined color="error" @click="$router.back()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text
        class="pt-3 overflow-y-auto overflow-x-hidden"
        style="max-height: 60vh"
      >
        <div class="my-2">
          <v-form ref="exitForm" v-model="isValid">
            <v-row>
              <!-- employee name -->
              <v-col cols="12" md="4">
                <v-text-field
                  :value="userName"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <!-- exit date -->
              <v-col cols="12" md="4">
                <v-menu
                  v-model="exitDateMenu"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.exitDate"
                      label="Exit Date"
                      outlined
                      dense
                      :disabled="!isEdit"
                      placeholder="Select Exit Date"
                      persistent-placeholder
                      :rules="[rules.exitDate]"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="formData.exitDate"
                    @click="exitDateMenu = false"
                    no-title
                    scrollable
                    :min="startDate"
                  />
                </v-menu>
              </v-col>
              <!-- reason code -->
              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="formData.reasonsCode"
                  :items="exitCodes"
                  :item-text="(item) => item.description"
                  :item-value="(item) => item.code"
                  label="Reason For Exit"
                  required
                  outlined
                  dense
                  :disabled="!isEdit"
                  placeholder="Select Reason For Exit"
                  persistent-placeholder
                  :rules="[rules.reasonsCode]"
                >
                </v-autocomplete>
              </v-col>
              <!-- reason description -->
              <v-col
                cols="12"
                v-if="formData.reasonsCode === 'OTHERS'"
                class="mt-n4"
              >
                <v-textarea
                  outlined
                  label="Reason description"
                  v-model="formData.reasonsDescription"
                  v-if="formData.reasonsCode === 'OTHERS'"
                  :disabled="!isEdit"
                  rows="2"
                />
              </v-col>
              <!-- expectedDateOfExit -->
              <v-col
                cols="12"
                md="6"
                class="mt-n4"
                v-if="$route.params.code && formData.status !== 'Open'"
              >
                <v-text-field
                  outlined
                  label="Expected date of exit"
                  v-model="formData.expectedDateOfExit"
                  disabled
                  dense
                />
              </v-col>
              <!-- attachments -->
              <v-col cols="12" md="6" class="mt-n4">
                <v-file-input
                  v-if="
                    !formData.status ||
                    formData.status === '' ||
                    formData.status === 'Open'
                  "
                  label="Attachment"
                  outlined
                  dense
                  placeholder="Select Attachment"
                  persistent-placeholder
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  @change="uploadFile"
                  :disabled="!isEdit"
                  accept="image/*, .pdf, .doc, .docx"
                />
              </v-col>
            </v-row>

            <!-- <v-divider></v-divider> -->
            <!-- <v-card-subtitle>Attachment</v-card-subtitle> -->
            <div v-if="formData.attachment">
              <div
                v-if="['.pdf'].includes(getFileExtension(formData.attachment))"
                max-width="250"
                height="150"
              >
                <v-card
                  class="mx-auto"
                  max-width="400"
                  max-height="150"
                  outlined
                  tile
                >
                  <v-row>
                    <v-col cols="4">
                      <v-card
                        class="ma-5 overflow-hidden"
                        max-width="100"
                        max-height="80"
                        outlined
                      >
                        <pdf
                          :page="1"
                          :src="`data:application/pdf;base64,${formData.attachment}`"
                        >
                        </pdf>
                      </v-card>
                    </v-col>
                    <v-col cols="6" align-self="center">
                      <span>{{ getName(formData.attachment) }}</span>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            class="mt-3"
                            @click="downloadFile(formData.attachment)"
                          >
                            <v-icon dark class="mx-2">cloud_download</v-icon>
                          </v-btn>
                        </template>
                        <span>download file</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <div
                v-if="
                  ['.png', '.jpeg', '.jpg'].includes(
                    getFileExtension(formData.attachment)
                  )
                "
                max-width="250"
                height="150"
              >
                <v-card
                  class="mx-auto"
                  max-width="400"
                  max-height="150"
                  outlined
                  tile
                >
                  <v-row>
                    <v-col cols="4">
                      <v-card
                        class="ma-3 overflow-hidden"
                        max-width="100"
                        max-height="80"
                        outlined
                      >
                        <v-img
                          :src="`data:${mimeType};base64, ${formData.attachment}`"
                          max-width="250"
                          max-height="150"
                        >
                        </v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="6" align-self="center">
                      <span>{{ getName(formData.attachment) }}</span>
                    </v-col>
                    <v-col cols="2" align-self="center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            color="primary"
                            class="mt-3"
                            @click="downloadFile(formData.attachment)"
                          >
                            <v-icon dark class="mx-2">cloud_download</v-icon>
                          </v-btn>
                        </template>
                        <span>download file</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <div
                v-if="
                  !['.png', '.jpeg', '.jpg', '.pdf'].includes(
                    getFileExtension(formData.attachment)
                  ) && isNew
                "
              >
                <div class="d-flex flex-column">
                  <v-btn
                    depressed
                    class="primary align-self-center mt-3"
                    @click="downloadFile(formData.attachment)"
                  >
                    Download File
                    <v-icon class="mx-2">cloud_download</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </div>

        <!-- <v-divider></v-divider> -->
        <!-- <v-card-subtitle>Employee Items</v-card-subtitle> -->
        <div>
          <v-data-table
            dense
            :headers="employeeItemsHeaders"
            :items="formData.employeeItems"
            hide-default-footer
            :show-select="false"
            item-key="no"
            v-if="$route.params.code !== undefined"
          >
            <template v-slot:[`item.returnable`]="{ item }">
              <div>
                <v-simple-checkbox
                  small
                  disabled
                  class="shrink mr-2"
                  v-model="item.returnable"
                ></v-simple-checkbox>
              </div>
            </template>
          </v-data-table>
        </div>
        <v-divider />

        <!-- approval entries -->
        <div
          v-if="
            formData.status !== 'Open' &&
            formData.status !== '' &&
            formData.status
          "
        >
          <ApprovalEntries :formData="formData" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-if="isEdit" class="primary" @click="save">save</v-btn>
      </v-card-actions>

      <v-dialog
        persistent
        v-model="checklistDialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        width="50%"
      >
        <v-card class="mx-auto">
          <v-card-subtitle class="d-flex mb-n4">
            <v-subheader class="mt-1"> Employee Checklist </v-subheader>
            <v-spacer></v-spacer>
            <v-btn
              icon
              x-small
              outlined
              color="error"
              class="mt-4"
              @click="checklistDialog = false"
            >
              <v-icon size="12"> mdi-close </v-icon>
            </v-btn>
          </v-card-subtitle>
          <v-divider class="mx-5"></v-divider>
          <v-card-text>
            <v-list dense rounded>
              <v-list-item
                v-for="(item, i) in employeeChecklist.employeeChecklist"
                :key="i"
                elevation="1"
              >
                <v-list-item-action>
                  <v-checkbox
                    readonly
                    v-model="item.done"
                    color="primary"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    :class="item.done ? 'styling text--disabled' : null"
                  >
                    {{ item.description }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import ApprovalEntries from "./ApprovalEntries.vue";
import FileMixin from "@kinetics254/cassandra-base/mixins/FileMixin";
import exitMixin from "../exitMixin";
import RouterMixin from "@kinetics254/cassandra-base/mixins/RouterMixin";
import call from "@kinetics254/cassandra-base/service/http";
import constants from "../exitConstants";
export default {
  name: "ExitCard",
  components: { pdf, ApprovalEntries },
  mixins: [FileMixin, exitMixin, RouterMixin],
  data() {
    return {
      dialog: true,
      checklistDialog: false,
      formData: {
        reasonsCode: "",
        reasonsDescription: "",
        exitDate: "",
        attachment: null,
        employeeItems: [],
      },
      exitDateMenu: false,
      isValid: false,
      appUrl: process.env.VUE_APP_TEMP_URL,
      isNew: true,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mimeType: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.params.code) {
        v.$store.dispatch("Exit/getExit", to.params.code);
      }
      v.$store.dispatch("Exit/exitCodes");
      v.$store.dispatch("Exit/employeeChecklist");
    });
  },
  computed: {
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    isEdit() {
      return this.$route.params.code !== undefined
        ? this.formData.status === "Open"
        : true;
    },
    exit() {
      return this.$store.getters["Exit/exitGetters"]("exit");
    },
    exitCodes() {
      return this.$store.getters["Exit/exitGetters"]("exitCodes");
    },
    employeeChecklist() {
      const employeeChecklist =
        this.$store.getters["Exit/exitGetters"]("employeeChecklist");
      return {
        employeeChecklist: employeeChecklist.sort((a, b) => (a.done ? 1 : -1)),
        count: employeeChecklist.filter((item) => item.done).length,
      };
    },
    rules() {
      return {
        reasonsCode: (v) => !!v || "Reasons is required",
        reasonsDescription: (v) => !!v || "Reasons Description is required",
        exitDate: (v) => !!v || "Exit Date is required",
      };
    },
    employeeItemsHeaders() {
      return [
        { text: "No", value: "no", align: "start" },
        { text: "Type", value: "type", align: "start" },
        { text: "Description", value: "description", align: "start" },
        { text: "Returnable", value: "returnable", align: "center" },
      ];
    },
    userName() {
      return this.$route.params.code
        ? this.formData.employeeNo + ":  " + this.formData.name
        : this.userData.employee +
            ":  " +
            this.userData.profile.first_name +
            " " +
            this.userData.profile.last_name;
    },
  },
  methods: {
    uploadFile: async function (value) {
      if (value) {
        this.isNew = false;
        this.formData.attachment = await helpers.toBase64(value);
        this.formData.attachment = this.formData.attachment.split(",")[1];
      }
    },
    close() {
      this.dialog = false;
      this.$emit("close");
    },
    save() {
      delete this.formData.employeeItems;
      const data = { ...this.formData };
      data.employeeNo = this.userData.employee;
      data.saveAndSend = false;
      this.$route.params.code !== undefined
        ? this.$store.dispatch("Exit/updateAndSendForApproval", data)
        : this.$store.dispatch("Exit/saveAndSendForApproval", data);
    },
    saveAndSend() {
      if (!this.isValid) {
        this.$refs.exitForm.validate();
      } else {
        delete this.formData.employeeItems;
        const data = { ...this.formData };
        data.employeeNo = this.userData.employee;
        data.saveAndSend = true;
        this.$route.params.code !== undefined
          ? this.$store.dispatch("Exit/updateAndSendForApproval", data)
          : this.$store.dispatch("Exit/saveAndSendForApproval", data);
      }
    },
    sendForApproval() {
      if (this.formData.questionnaireAnswers.length === 0) {
        this.$confirm.show({
          title: "Send for Approval",
          text: `Kindly confirm that you have filled the Exit questionnaire before sending for approval`,
          onConfirm: () => {},
        });
      } else {
        this.$store.dispatch("Exit/sendForApproval", this.formData);
      }
    },
    cancelSendForApproval() {
      delete this.formData.employeeItems;
      this.$store.dispatch("Exit/cancelApproval", this.formData);
    },

    downloadClearanceForm: function () {
      call("post", "exit/clearance-form", { no: this.formData.no })
        .then((res) => {
          this.downloadLink(res.data.data);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    getFileExtension(b64) {
      return helpers.fileExtension(b64);
    },
    getPages: function (document) {
      if (!document) return 0;
      const bin = atob(document);
      return bin.match(/\/Type\s*\/Page\b/g).length;
    },
    getName(file) {
      let fileExt = this.getFileExtension(file);
      let fileName = `file-${new Date().getTime()}.${fileExt}`;
      return fileName;
    },
    getColor(status) {
      return helpers.getColor(status);
    },
    getMimeType(base64) {
      helpers.getFileType(base64).then((res) => {
        this.mimeType = res.mime;
        this.base64Ext = res.ext;
      });
    },
  },
  watch: {
    exit: {
      handler() {
        this.formData = { ...this.exit };
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.styling {
  text-decoration-line: line-through;
  text-decoration-color: grey;
  text-decoration-thickness: 2px;
}
</style>
