import { render, staticRenderFns } from "./AppraisalApprovalCard.vue?vue&type=template&id=fe2b1962&scoped=true"
import script from "./AppraisalApprovalCard.vue?vue&type=script&lang=js"
export * from "./AppraisalApprovalCard.vue?vue&type=script&lang=js"
import style0 from "./AppraisalApprovalCard.vue?vue&type=style&index=0&id=fe2b1962&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe2b1962",
  null
  
)

export default component.exports