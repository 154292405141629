import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Exit Management "),_c(VSpacer),_c(VBtn,{staticClass:"primary text--capitalize",on:{"click":_vm.addExit}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("mdi-plus")]),_vm._v(" Add Exit Notice ")],1)],1),_c(VCardText,[_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.exits,"dense":""},scopedSlots:_vm._u([{key:"item.reasonsDescription",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--truncate",staticStyle:{"width":"70%"}},[_vm._v(_vm._s(item.reasonsDescription))])]}},{key:"item.exitDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDateFormat(item.exitDate)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","label":"","color":_vm.getColor(item.status).color}},[_c('div',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(item.status))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"teal lighten-4","label":"","small":""},on:{"click":function($event){return _vm.editExit(item)}}},'v-chip',attrs,false),on),[(item.status === 'Open')?_c(VIcon,{attrs:{"color":"teal darken-4","small":""}},[_vm._v(" mdi-pencil ")]):_c(VIcon,[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status === "Open" ? "edit" : "view"))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Pending Approval'),expression:"item.status === 'Pending Approval'"}],staticClass:"mx-1",attrs:{"color":"orange lighten-4","label":"","small":""},on:{"click":function($event){return _vm.cancelExitApproval(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"orange darken-4","small":""}},[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("cancel approval request")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"mx-1",attrs:{"color":"blue lighten-4","label":"","small":""},on:{"click":function($event){return _vm.exitApprovalRequest(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"blue darken-4","small":""}},[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("send for approval")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VChip,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.status === 'Open'),expression:"item.status === 'Open'"}],staticClass:"mx-1",attrs:{"color":"red lighten-4","label":"","small":""},on:{"click":function($event){return _vm.deleteExit(item)}}},'v-chip',attrs,false),on),[_c(VIcon,{attrs:{"color":"red darken-4","small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("delete")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }