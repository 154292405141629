<template>
  <v-container fluid grid-list-xl>
    <v-app-bar
      class="leaveBar"
      dense
      style="background-color: #f0f3f4"
      :style="{ border: '0' }"
    >
      <div class="text-lg-h5" style="font-weight: 300">Leave Statistics</div>
      <v-spacer></v-spacer>
      <v-breadcrumbs
        customDivider
        divider="/"
        large
        :items="path"
      ></v-breadcrumbs>
    </v-app-bar>
    <div>
      <StatsView :data="applicationStats">
        <template #stats>
          <v-layout row wrap>
            <v-flex
              v-for="(item, i) in applicationStats"
              :key="i"
              xs6
              sm4
              xl2
              class="md3 lg5-custom"
            >
              <v-hover v-slot="{ hover }" open-delay="200">
                <v-card
                  @click="
                    $router.replace({
                      path: item.routePath,
                      query: item.params,
                    })
                  "
                  :elevation="hover ? 16 : 0"
                  :class="{ 'on-hover': hover }"
                  flat
                  style="background-color: #ffffff"
                >
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-lg-h3">{{
                          item.count
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          item.name
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar :color="item.avatarColor" size="70">
                          <v-icon large :color="item.iconColor">{{
                            item.icon
                          }}</v-icon>
                        </v-avatar>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-hover>
            </v-flex>
          </v-layout>
        </template>
      </StatsView>
    </div>
    <div class="my-4">
      <v-row>
        <v-col cols="12" md="6">
          <v-card flat class="nx-auto" style="background-color: #ffffff">
            <v-card-title> Leave History </v-card-title>
            <v-card-text
              style="height: 41.5vh; overflow-y: scroll"
              id="scollStyle"
            >
              <v-timeline align-center dense class="ml-n10">
                <v-timeline-item
                  color="secondary"
                  small
                  v-for="(item, i) in applications"
                  :key="i"
                >
                  <v-row class="pt-1">
                    <v-col cols="7">
                      <span class="text-caption">
                        {{ getDateFormat(item.application_date) }}
                      </span>
                      <br />
                      <div class="d-flex justify-space-between">
                        <strong class="text-capitalize text-h6">
                          {{ item.leave_code.toLowerCase() + " Leave" }}
                        </strong>
                        <v-chip small color="warning" class="mt-1">
                          {{ item.status }}
                        </v-chip>
                      </div>
                      <div class="caption">
                        Hand over to:
                        {{ item.hand_over_to_name }}
                      </div>
                    </v-col>

                    <v-col cols="5">
                      <div class="caption">
                        Start: {{ getDateFormat(item.start_date) }}
                      </div>
                      <div class="caption">
                        End: {{ getDateFormat(item.end_date) }}
                      </div>
                      <div class="caption">
                        {{ item.approved_days + " Day(s)" }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-2"></v-divider>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card flat class="mx-auto" style="background-color: #ffffff">
            <v-card-title>Leave Allocation</v-card-title>
            <v-card-text
              style="height: 41.5vh; overflow-y: scroll"
              id="scollStyle"
            >
              <v-row>
                <v-col cols="12" v-for="(item, i) in allocations" :key="i">
                  <v-card
                    @click="leave_type = item.leave_code"
                    class="mx-auto"
                    flat
                    style="background-color: #ffffff"
                  >
                    <v-list dense :color="item.avatarColor">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title :class="item.textColor">
                            <div class="d-flex justify-space-between my-3">
                              <div class="text-lg-h6 text-uppercase">
                                {{
                                  item.leave_code.toLowerCase() + " leave" + "-"
                                }}
                                <span class="text-lg-h6">{{
                                  item.LeaveDescription
                                }}</span>
                              </div>
                              <div :class="item.textColor" class="my-auto">
                                {{ "Balance: " + item.balance + " Days" }}
                              </div>
                            </div>
                          </v-list-item-title>
                          <v-list-item-title :class="item.textColor">
                            <div class="d-flex justify-space-between">
                              <div class="">
                                {{ `Taken: ${item.taken} Day(s)` }}
                              </div>
                              <div :class="item.textColor" class="my-auto">
                                {{
                                  `Balance B/F: ${item.balance_brought_forward} Day(s)`
                                }}
                              </div>
                            </div>
                          </v-list-item-title>
                          <v-list-item-title :class="item.textColor">
                            {{ `Accrued: ${item.accrued_days} Day(s)` }}
                          </v-list-item-title>
                          <v-list-item-subtitle :class="item.textColor">
                            {{ "allocated: " + item.allocated_days + " days," }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-avatar :color="item.avatarColor" size="50">
                            <v-icon :color="item.iconColor" size="45">{{
                              item.icon
                            }}</v-icon>
                          </v-avatar>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style></style>
<script>
import { leaveMixin, SetupMixin } from "../../../mixins";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import StatsView from "@kinetics254/cassandra-dashboards/Stats/StatsView.vue";
import LeaveMethods from "../LeaveMethods";

export default {
  name: "LeaveDashboard",
  components: { /** LineChart */ StatsView },
  mixins: [SetupMixin, leaveMixin],
  data: () => ({
    width: "100%",
    height: "400",
    type: "column2d",
    dataFormat: "json",
    leave_type: null,
  }),
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.loadMyApplications();
      v.getSetup();
    });
  },
  computed: {
    applications: function () {
      const data = this.getApplications()
        .map((app) => app.leaveData)
        .filter((el) => el.status === "Posted");
      return data.reverse();
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    applicationStats: function () {
      const applications =
        this.$store.getters["leave/leaveGetter"]("applications");
      return [
        {
          count: applications.length,
          name: "All Aplications",
          avatarColor: "light-blue lighten-4",
          iconColor: "light-blue darken-4",
          routePath: "/leave/application",
          icon: "ti-calendar",
        },
        {
          count: applications.filter((app) => app.status === "Released").length,
          name: "Released",
          avatarColor: "green accent-2",
          iconColor: "green darken-4",
          icon: "event_available",
          routePath: "/leave/application",
          params: { id: "Released" },
        },
        {
          count: applications.filter((app) => app.status === "Posted").length,
          name: "Posted",
          avatarColor: "teal accent-2",
          iconColor: "teal darken-4",
          icon: "book_online",
          routePath: "/leave/application",
          params: { id: "Posted" },
        },
        {
          count: applications.filter((app) => app.status === "Review").length,
          name: "In Review",
          avatarColor: "cyan accent-1",
          iconColor: "cyan darken-4",
          icon: "date_range",
          routePath: "/leave/application",
          params: { id: "Review" },
        },
        {
          count: applications.filter((app) => app.status === "Open").length,
          name: "Open",
          avatarColor: "orange accent-1",
          iconColor: "orange darken-4",
          icon: "schedule_send",
          routePath: "/leave/application",
          params: { id: "Open" },
        },
      ];
    },

    appraisalStats() {
      const appraisals = this.getChartData(
        this.$store.getters["appraisal/stats"]
      );
      return {
        options: this.getChartOptions("Weekly Applications"),
        data: {
          labels: appraisals.labels,
          datasets: [
            {
              label: "All",
              data: appraisals.data,
              borderColor: "#706fd3",
              backgroundColor: "rgb(112,111,211, 0)",
            },
          ],
        },
      };
    },
    path: function () {
      return this.$router.currentRoute.meta.breadcrumb;
    },
  },
  mounted() {
    console.log(this.lineData);
  },
  methods: {
    ...LeaveMethods,
    getDateFormat(date) {
      return helpers.getDateFormat(date);
    },
    loadMyApplications: function () {
      const employeeNo = this.userData.employee;
      const data = { employeeNo };
      this.$store.dispatch("leave/getApplications", data);
    },
    viewApplications: function (item) {
      this.$router.push({ path: "/leave/application", query: item.params });
    },
  },
};
</script>
