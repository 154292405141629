import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{},[_vm._v(" "+_vm._s(_vm.editing ? "Edit" : "Add")+" Appraisal KPI "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","outlined":"","color":"red"},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VDivider),(_vm.entity)?_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VRow,{staticClass:"mt-2",attrs:{"dense":"","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Description","disabled":_vm.editing},model:{value:(_vm.editedData.kpiDescription),callback:function ($$v) {_vm.$set(_vm.editedData, "kpiDescription", $$v)},expression:"editedData.kpiDescription"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Weight"},model:{value:(_vm.editedData.weight),callback:function ($$v) {_vm.$set(_vm.editedData, "weight", $$v)},expression:"editedData.weight"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Target Value"},model:{value:(_vm.editedData.target),callback:function ($$v) {_vm.$set(_vm.editedData, "target", $$v)},expression:"editedData.target"}})],1)],1),_c(VBtn,{attrs:{"rounded":"","type":"submit","color":"primary"}},[_vm._v("Save")])],1)],1):_c(VCardText,[_vm._v(" Error while Loading the Form ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }