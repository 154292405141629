import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
const user = AuthService.user;
const caption = user
  ? user.captions
    ? user.captions.exit_caption
    : undefined
  : undefined;

export default [
  {
    name: caption ? caption : "Resignation",
    icon: "exit_to_app",
    to: "/exit",
    enabled: true,
  },
];
