import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.supervisorReviews},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["code"]))])]}},{key:"item.currentStage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["currentStage"]))])]}},{key:"item.employeeScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.MyScore(item))+"%")])]}},{key:"item.supervisorScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.SupervisorScore(item))+"%")])]}},{key:"item.overalScore",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(_vm.OverallScore(item))+"%")])]}},{key:"item.documentStatus",fn:function(ref){
var item = ref.item;
return [_c(VChip,{directives:[{name:"show",rawName:"v-show",value:(item.documentStatus),expression:"item.documentStatus"}],attrs:{"small":"","color":_vm.getColor(item.documentStatus).color,"dark":""}},[_c('span',{staticClass:"supervisor-review-table"},[_vm._v(_vm._s(item["documentStatus"]))])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VBtnToggle,[(item.documentStatus === 'Reviewed')?_c(VBtn,{staticClass:"mx-2 white--text",attrs:{"small":"","color":"primary","tile":"","link":""},on:{"click":function($event){return _vm.downloadEmployeeReport(item.code)}}},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v(" mdi-download ")]),_vm._v(" report ")],1):_vm._e(),_c(VBtn,{attrs:{"small":"","color":"primary","tile":"","disabled":(item.documentStatus === 'Reviewed' ||
            item.periodAPI.closed === true) &&
          _vm.tab !== '2nd',"to":{
          name: 'Appraisal Card',
          params: { code: item.code },
        }}},[_vm._v(" "+_vm._s(_vm.tab === "2nd" && item.documentStatus === "Reviewed" ? "comment" : "Appraise")+" "),_c(VIcon,{attrs:{"right":"","color":"white"}},[_vm._v("mdi-arrow-right")])],1),_c(VBtn,{staticClass:"ml-2",attrs:{"small":"","color":"primary","tile":"","disabled":item.documentStatus === 'Reviewed' || item.periodAPI.closed === true,"to":{
          name: 'TrainingCard',
        }}},[_vm._v(" Training Request "),_c(VIcon,{attrs:{"right":"","color":"white"}},[_vm._v("mdi-arrow-right")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }